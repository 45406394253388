import axios from "axios";
import { envi } from "./Helpers";
/** Returns the configuration for the custom catalogs used for the `wbk.configuration` message. */
export async function getCustomCatalogConfiguration() {
    console.info("getCustomCatalogConfiguration");
    const { data } = await axios.get(envi(new URL(window.location.href).toString()) + "Product/GetCustomCatalogConfig");
    return data;
}
/**
 * Send example the data of the example custom catalog to the basket.
 */
export function handleCustomCatalogArticlesMessage(pApp, pMessage) {
    console.info('handleCustomCatalogArticlesMessage');
    let data = [];
    const asyncCatalogConfig = async () => {
        const response = await axios.get(envi(new URL(window.location.href).toString()) + "Product/GetCustomCatalogArticles");
        data = response.data;
        let tArticles = [];
        const tQuery = pMessage.parameter;
        console.log('tQuery');
        console.log(tQuery);
        tArticles = data.filter(cca => cca.catalogId === tQuery.catalogId);
        const tMessage = {
            type: "wbk.customCatalogArticles",
            parameter: tArticles
        };
        pApp.sendMessageToBasket(tMessage);
    };
    asyncCatalogConfig()
        .then(result => {
        console.log('Done running asyncCatalogConfig()');
        console.log(result);
    })
        .catch(error => {
        console.error(error);
    });
}
/**
 * Send example data of custom afticles to the basket.
 */
export function handleCustomArticleMessage(pApp, pMessage) {
    const tQuery = pMessage.parameter;
    console.log('-handleCustomArticleMessage-');
    console.log(pMessage);
    console.log(tQuery);
    let data;
    const asyncCatalogConfig = async () => {
        const tQuery = pMessage.parameter;
        console.log('tQuery.articleId');
        console.log(tQuery.articleId);
        const response = await axios.get(envi(new URL(window.location.href).toString()) + "Product/GetCustomArticle?productId=" + tQuery.articleId);
        data = response.data;
        const tArticle = data;
        if (tArticle == null) {
            throw new Error("Unknown article");
        }
        const tMessage = {
            type: "wbk.customArticle",
            parameter: tArticle
        };
        pApp.sendMessageToBasket(tMessage);
    };
    asyncCatalogConfig()
        .then(result => {
        console.log('Done running asyncCatalogConfig()');
        console.log(result);
    })
        .catch(error => {
        console.error(error);
    });
}
