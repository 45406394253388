import { Money } from "@easterngraphics/wcf/modules/eaiws/basket";
import { toLocaleCurrencyString, toLocaleString } from "@easterngraphics/wcf/modules/utils/number";
import { NETVALUE_CALCLINE_LEVEL } from "../Config";
import { fetchItemInfo } from "./ItemData";
/**
 * Helper to show an item in the item list.
 */
export function showItem(pSession, pItem) {
    function addCell(pValue) {
        const tCell = document.createElement("td");
        tCell.innerText = pValue !== null && pValue !== void 0 ? pValue : "";
        tRow.appendChild(tCell);
    }
    const tTable = document.getElementById("itemList");
    const tRow = document.createElement("tr");
    addCell(pItem.positionNumber);
    addCell(pItem.label);
    addCell(pItem.itemType);
    //because we requested view items the field `itemId` represents the id of the view item
    //we have to use the `basketItemIds`in this case
    let tBasketItemId;
    if (pItem.basketItemIds != null) {
        tBasketItemId = pItem.basketItemIds[0];
    }
    else {
        tBasketItemId = pItem.itemId;
    }
    addCell(tBasketItemId);
    //add button cell
    const tCell = document.createElement("td");
    const tButton = document.createElement("button");
    tButton.innerText = "i";
    tButton.style.minWidth = "24px";
    tButton.onclick = () => fetchAndShowItemInfo(pSession, tBasketItemId, pItem.positionNumber);
    tCell.appendChild(tButton);
    tRow.appendChild(tCell);
    tTable.appendChild(tRow);
}
/**
 * This function collects information about an item from EAIWS and shows it in the item list.
 */
async function fetchAndShowItemInfo(pSession, pBasketItemId, pItemNumber) {
    const tInfo = await fetchItemInfo(pSession, pBasketItemId);
    showItemInfo(pItemNumber, tInfo);
}
/**
 * Helper to show additional information for an item.
 */
function showItemInfo(pItemNumber, pItemInfo) {
    function addInfo(pName, pValue, pRed = false) {
        const tInfo = document.createElement("div");
        const tInfoName = document.createElement("span");
        const tInfoValue = document.createElement("span");
        tInfo.style.paddingBottom = "6px";
        if (pRed === true) {
            tInfo.style.color = "red";
        }
        tInfoName.style.fontWeight = "600";
        tInfoName.innerText = pName + ": ";
        tInfoValue.innerText = pValue !== null && pValue !== void 0 ? pValue : "";
        tInfo.appendChild(tInfoName);
        tInfo.appendChild(tInfoValue);
        tItemInfoContent.appendChild(tInfo);
    }
    function formatPrice(pValue, pCurrency) {
        var _a;
        if (pValue == null || pCurrency == null)
            return ("");
        return ((_a = toLocaleCurrencyString(pValue, pCurrency, "en-US")) !== null && _a !== void 0 ? _a : "");
    }
    function formatQuantity(pValue) {
        var _a;
        if (pValue == null)
            return ("");
        return ((_a = toLocaleString(pValue, "en-US")) !== null && _a !== void 0 ? _a : "");
    }
    //clear item info
    const tItemInfoContent = document.getElementById("itemInfoContent");
    tItemInfoContent.innerHTML = "";
    const tItemProps = pItemInfo.itemProperties;
    const tCalculation = pItemInfo.calculation.sheet;
    addInfo("Item Number", pItemNumber);
    addInfo("Type", tItemProps.itemType);
    addInfo("Item ID", tItemProps.itemId);
    if (tItemProps.article != null) {
        addInfo("Manufacturer ID", tItemProps.article.manufacturerId);
        addInfo("Manufacturer Name", tItemProps.article.manufacturerName);
        addInfo("Series ID", tItemProps.article.seriesId);
        addInfo("Series Name", tItemProps.article.seriesName);
        if (tItemProps.article.useFinalArticleNumber === true) {
            addInfo("Article Number", tItemProps.article.finalArticleNumber);
        }
        else {
            addInfo("Article Number", tItemProps.article.baseArticleNumber);
        }
        addInfo("Variant Code", tItemProps.article.variantCode);
        addInfo("Short Text", tItemProps.article.shortText);
        addInfo("Long Text", tItemProps.article.longText);
        addInfo("Feature Text", tItemProps.article.featureText);
        if (tItemProps.article.inconsistencyReason != null && tItemProps.article.inconsistencyReason.length > 0) {
            addInfo("Inconsistency Reason", tItemProps.article.inconsistencyReason.join("\n"), true);
        }
        addInfo("Base Purchase Price", formatPrice(tItemProps.article.purchasePrice, tItemProps.article.purchaseCurrency));
        addInfo("Base Sales Price", formatPrice(tItemProps.article.salesPrice, tItemProps.article.salesCurrency));
        addInfo("Quantity", formatQuantity(tItemProps.article.quantity));
    }
    else {
        addInfo("Label", tItemProps.label);
    }
    //find net value in calculation and print it
    let tNetValueCalcLine = undefined;
    for (let i = 0; i < tCalculation.lines.length; ++i) {
        if (tCalculation.lines[i].level === NETVALUE_CALCLINE_LEVEL) {
            tNetValueCalcLine = tCalculation.lines[i];
            break;
        }
    }
    if (tNetValueCalcLine != null) {
        if (tNetValueCalcLine.amount != null && tNetValueCalcLine.amount instanceof Money) {
            addInfo("Unit Net Price", formatPrice(tNetValueCalcLine.amount.value, tNetValueCalcLine.amount.currency));
        }
        if (tNetValueCalcLine.value != null) {
            addInfo("Total Net Price", formatPrice(tNetValueCalcLine.value.value, tNetValueCalcLine.value.currency));
        }
    }
    //item image
    if (pItemInfo.imageUrl != null && pItemInfo.imageUrl != "") {
        const tImage = document.createElement("img");
        tImage.src = pItemInfo.imageUrl;
        tImage.style.maxWidth = "200px";
        tItemInfoContent.appendChild(tImage);
    }
}
