import { ClientMessage, StringPair } from "@easterngraphics/wcf/modules/eaiws/session";
import { PConLoginClient } from "../../libs/pcon-login/pcon-login";
import { PCON_LOGIN_CLIENT_ID, PCON_LOGIN_SCOPE } from "../Config";
const POPUP_URL = "login-popup.html";
export class LoginHandler {
    get accessToken() {
        var _a;
        return ((_a = this.mAccesToken) !== null && _a !== void 0 ? _a : undefined);
    }
    constructor(pApp) {
        this.mApp = pApp;
        this.mAccesToken = null;
        //create an instance of PConLoginClient
        this.mLoginClient = new PConLoginClient({
            client_id: PCON_LOGIN_CLIENT_ID,
            scope: PCON_LOGIN_SCOPE,
            redirect_uri: window.location.origin + window.location.pathname + POPUP_URL,
        });
    }
    /**
     * Function which starts the pCon.login process using a popup.
     */
    async loginWithPopup() {
        //check if client id is configured
        if (PCON_LOGIN_CLIENT_ID == null || PCON_LOGIN_CLIENT_ID == "") {
            alert('PCON_LOGIN_CLIENT_ID not defined.\nPlease configure a valid pCon.login client id in the "config.ts" file.');
            return (false);
        }
        //check if example is hosted on a webserver (no "file://")
        if (!window.location.protocol.startsWith("http")) {
            alert("Please use a webserver to run this example. pCon.login will not be able to call the redirect url otherwise.");
            return (false);
        }
        //show the login popup and wait until its finished
        const tLoggedIn = await this.showLoginPopup();
        if (!tLoggedIn) {
            return (false);
        }
        //get access token
        this.mLoginClient.handlePopupFinished();
        this.mAccesToken = await this.mLoginClient.getAccessToken();
        console.log(this.mAccesToken);
        //keep license live (IMPORTANT: check error handling in this function)
        this.startLicenseKeepAlive();
        //make sure the basket ui always has a valid access token
        this.startBasketAccessTokenUpdate();
        return (true);
    }
    /** get current access token from pCon.login */
    async updateAccessToken(pForceRefresh = false) {
        this.mAccesToken = await this.mLoginClient.getAccessToken(pForceRefresh);
    }
    /** Makes sure the license will not expire. */
    startLicenseKeepAlive() {
        //trigger license update every 30 seconds to keep the license alive
        setInterval(async () => {
            try {
                await this.updateAccessToken(); //make sure a valid access token is used
                //prepare message and send it to the EAIWS session guard plugin
                const tMessage = new ClientMessage();
                tMessage.target = "plugin:eaiws::plugin::session_guard";
                tMessage.messageId = "updateToken";
                tMessage.data = [
                    new StringPair("accessToken", this.accessToken),
                    new StringPair("licenseType", "user")
                ];
                const tResult = await this.mApp.session.session.sendMessage([tMessage]);
                //check if token update was successfull
                let tUpdateFailed = true;
                if (tResult.sessionAlive) {
                    tUpdateFailed = tResult.messages.length > 0 && tResult.messages[0].statusCode !== "OK";
                }
                if (tUpdateFailed) {
                    throw new Error("Failed to update token:\n" + JSON.stringify(tResult, undefined, 2));
                }
            }
            catch (pError) {
                /*
                IMPORTANT: You have to handle this error.
                The license keep alive failed. That means the current EAIWS session is no longer licensed.
                The session will be closed automatically after 15 minutes without a license.
                To prevent data loss you should notify the user that he should save his work and restart the basket.
                */
                alert("License keep alive failed:\n" + pError);
            }
        }, 30000);
    }
    /** Ensures that the basket ui always has a valid access token */
    startBasketAccessTokenUpdate() {
        /*
        Access tokens are valid for one hour. To make sure that the basket UI is using a valid
        token we send a new token every 30 minuten to the basket.
        */
        setInterval(async () => {
            await this.updateAccessToken(true); //request a new token using the "force" option
            if (this.accessToken != null) {
                const tMessage = {
                    type: "wbk.updateUserAccessToken",
                    parameter: this.accessToken
                };
                this.mApp.sendMessageToBasket(tMessage);
            }
        }, 1800000);
    }
    /** Creates and shows the login popup. Returns false login was aborted by user. */
    async showLoginPopup() {
        let tLoggedIn = false;
        let tPopup = null;
        let popupPromiseResolve;
        //Message handler which handles the "login-popup-finished" message from the popup
        const onMessage = (pEvent) => {
            if (pEvent.source === tPopup && pEvent.data === "login-popup-finished") {
                tLoggedIn = true;
                if (popupPromiseResolve != null && tPopup != null) {
                    popupPromiseResolve();
                    tPopup.close();
                }
            }
        };
        //Timer which checks if the popup was closed. This is necassary because the user may close the popup without loggin in.
        const tCloseTimer = setInterval(() => {
            if (popupPromiseResolve != null && tPopup != null && tPopup.closed) {
                clearInterval(tCloseTimer);
                popupPromiseResolve();
            }
        }, 500);
        //register temporary message handler
        window.addEventListener("message", onMessage);
        //create the popup window and wait untel the promise resolves
        await new Promise((resolve, reject) => {
            popupPromiseResolve = resolve;
            tPopup = window.open(POPUP_URL, "_blank", this.getPopupFeatures());
        });
        //remove the temporary message handler
        window.removeEventListener("message", onMessage);
        return (tLoggedIn);
    }
    /** Returns the feature string for the login popup window */
    getPopupFeatures() {
        //position the popup in the center of the screen
        const tWidth = 500;
        const tHeight = 600;
        const tLeft = Math.round(window.screen.width / 2 - tWidth / 2);
        const tTop = Math.round(window.screen.height / 2 - tHeight / 2);
        return (`popup,width=${tWidth},height=${tHeight},left=${tLeft},top=${tTop}`);
    }
}
