import { EaiwsSession } from "@easterngraphics/wcf/modules/eaiws";
import { LoginHandler } from "./login/LoginHandler";
import { BASKET_URL, PCON_LOGIN_CLIENT_ID, } from "./Config";
import { prepareAndSendConfiguration } from "./utils/BasketConfig";
import { handleCustomArticleMessage, handleCustomCatalogArticlesMessage, } from "./utils/CustomCatalog";
import { savePDF } from "./utils/Export";
import { getAllItems } from "./utils/ItemData";
import { showItem } from "./utils/ItemListGui";
import axios from "axios";
import { envi } from "./utils/Helpers";
export class Application {
    get session() {
        return this.mSession;
    }
    get loginHandler() {
        return this.mLoginHandler;
    }
    constructor() {
        this.onMessage = (pEvent) => {
            //check if message is coming from basket
            if (pEvent.source !== this.mIFrame.contentWindow)
                return;
            //call our handler
            this.handleBasketMessage(pEvent.data);
        };
        console.log("Application started.");
        console.log(window.location.href);
        const url = new URL(window.location.href);
        const params = url.searchParams;
        const quote = params.get("quoteId");
        console.log(quote);
        this.mIFrame = document.getElementById("basketFrame"); //get basket iframe
        this.mSession = new EaiwsSession();
        this.mLoginHandler = new LoginHandler(this);
        //register message handler to receive messages from basket
        window.addEventListener("message", this.onMessage);
        // setup initial value for the "Use pCon.login" checkbox
        document.addEventListener("DOMContentLoaded", () => {
            document.getElementById("checkboxUseLogin").checked =
                PCON_LOGIN_CLIENT_ID != "" &&
                    window.location.protocol.startsWith("http");
            if (document.getElementById("checkboxUseLogin").checked) {
                this.startBasket();
            }
        });
    }
    async startBasket() {
        //login user
        const tLoggedIn = await this.login();
        if (!tLoggedIn) {
            return;
        }
        //hide intro ui
        document.getElementById("intro").style.display = "none";
        //load pCon.basket in integration mode and make iframe visible
        this.mIFrame.src = BASKET_URL;
        this.mIFrame.style.display = "block";
    }
    async saveOBK() {
        //get OBK url and download it
        await this.saveProject();
    }
    async saveOBX() {
        var _a, _b;
        const user = await axios.get("https://login.pcon-solutions.com/api/v1/account/user", {
            headers: {
                Authorization: "Bearer " + this.loginHandler.accessToken,
            },
        });
        //get url of OBX and download it
        const url = new URL(window.location.href);
        const params = url.searchParams;
        const quoteId = params.get("quoteId");
        if (quoteId) {
            console.log("before axios;");
            try {
                await axios.get(envi(new URL(window.location.href).toString()) +
                    "Session/SetSession?server=" +
                    encodeURIComponent(this.session.baseUrl) +
                    "&sessionId=" +
                    this.session.sessionId +
                    "&keepAliveInterval=69120");
                const res = await axios.get(envi(new URL(window.location.href).toString()) +
                    "Quote/SaveSessionToCrm?sessionId=" +
                    this.session.sessionId +
                    "&quoteId=" +
                    quoteId +
                    "&userName=" +
                    user.data.email);
                alert("Tilbud er oppdatert med tilbudslinjer i CRM. Revider tilbudet i CRM for å oppdatere det.");
                console.log(res);
            }
            catch (error) {
                if ((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data)
                    alert((_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.data);
                else
                    alert("Lagring misslyktes: Trykk Save OBK og ta kontakt med systemadmin.");
            }
        }
    }
    async savePDF() {
        //get url of PDF and download it
        window.location.href = await savePDF(this.session);
    }
    async restart() {
        //close the session
        await this.mSession.close();
        //reload example
        window.location.reload();
    }
    /**
     * This function is used to send messages to the basket
     */
    sendMessageToBasket(pMessage) {
        if (this.mIFrame.contentWindow != null) {
            this.mIFrame.contentWindow.postMessage(pMessage, "*");
        }
    }
    /**
     * This function handles every message coming from pCon.basket
     */
    handleBasketMessage(pMessage) {
        console.dir(pMessage);
        switch (pMessage.type) {
            //basket requests its configuration => prepare configuration and send it to basket
            case "wbkHost.getConfiguration": {
                const tMessage = pMessage;
                console.log("Basket version: " + tMessage.parameter.appVersion);
                console.log("Api version: " +
                    tMessage.parameter.messageApiVersion.major +
                    "." +
                    tMessage.parameter.messageApiVersion.minor);
                prepareAndSendConfiguration(this);
                break;
            }
            //basket requests the article data for a custom article
            case "wbkHost.getCustomArticle": {
                handleCustomArticleMessage(this, pMessage);
                break;
            }
            //basket requests the custom articles for our custom catalog
            case "wbkHost.getCustomCatalogArticles": {
                handleCustomCatalogArticlesMessage(this, pMessage);
                break;
            }
            //project title changed
            case "wbkHost.projectTitleChanged": {
                //reset project title to "unnamed" if empty
                if (pMessage.parameter === "") {
                    this.sendMessageToBasket({
                        type: "wbk.setProjectTitle",
                        parameter: "unnamed",
                    });
                }
                break;
            }
            //user clicked the save project button
            case "wbkHost.saveProject": {
                this.saveProject();
                break;
            }
            //user is finished with editing
            case "wbkHost.done": {
                this.showItemList();
                break;
            }
        }
    }
    /** Login user using pCon.login */
    async login() {
        //check if login is enabled
        const tUseLogin = document.getElementById("checkboxUseLogin").checked;
        //loggin with pCon.login
        if (tUseLogin) {
            const tLoggedIn = await this.mLoginHandler.loginWithPopup();
            return tLoggedIn;
        }
        return true;
    }
    /**
     * This function is called when the user clicks the save button in pCon.basket UI and will save an OBK file.
     */
    async saveProject() {
        var _a, _b;
        // //save OBK
        console.log("save project");
        console.log(this.session.sessionId);
        const url = new URL(window.location.href);
        const params = url.searchParams;
        const qutoeId = params.get("quoteId");
        if (qutoeId) {
            console.log("before axios;");
            try {
                await axios.get(envi(new URL(window.location.href).toString()) +
                    "Session/SetSession?server=" +
                    encodeURIComponent(this.session.baseUrl) +
                    "&sessionId=" +
                    this.session.sessionId +
                    "&keepAliveInterval=69120");
                await axios.post(envi(new URL(window.location.href).toString()) +
                    "Quote/SetObk?sessionId=" +
                    this.session.sessionId +
                    "&quoteId=" +
                    qutoeId);
                alert("OBK-fil lagret i CRM.");
            }
            catch (error) {
                if ((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data)
                    alert((_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.data);
                else
                    alert("Lagring misslyktes. Ta kontakt med systemadmin.");
            }
        }
        let tMessage = {
            type: "wbk.saveProjectDone",
        };
        // let tMessageOBX: WbkSaveProjectDoneMessage = {
        //     type: "wbk.saveProjectDone"
        // }
        this.sendMessageToBasket(tMessage);
    }
    /**
     * This function collects all items and displays them in the item list.
     */
    async showItemList() {
        //close/hide basket first
        this.mIFrame.src = "";
        this.mIFrame.style.display = "none";
        //show output
        document.getElementById("output").style.display = "block";
        //get all items and show the
        const tItems = await getAllItems(this.mSession, true);
        for (var i = 0; i < tItems.length; ++i) {
            showItem(this.mSession, tItems[i]);
        }
    }
}
