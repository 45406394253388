import { GATEKEEPER_ID, PRICING_PROCEDURE_NAME, REPORT_TEMPLATE, DEFAULT_REPORT_TEMPLATE, } from "../Config";
import { getCustomCatalogConfiguration } from "./CustomCatalog";
import { getSessionFromGatekeeper } from "./Gatekeeper";
import { setAccountData, setContactData, setExampleCustomerAddress, setExampleProjectData, } from "./HeaderData";
import { envi, setExchangeRates } from "./Helpers";
import { loadOBK, loadOBX } from "./Import";
import { setupPriceConditions } from "./PriceConditions";
import axios from "axios";
/**
 * This function prepares the configuration of pCon.basket including the EAIWS session and sends it to the basket.
 */
export async function prepareAndSendConfiguration(pApp) {
    //option1: create EAIWS session using gatekeeper. see gatekeeper documentation: https://eaiws-server.pcon-solutions.com/doc/v3
    await getSessionFromGatekeeper(pApp.session, GATEKEEPER_ID, pApp.loginHandler.accessToken);
    console.log(pApp.session.baseUrl);
    const url = new URL(window.location.href);
    const params = url.searchParams;
    const quoteId = params.get("quoteId");
    console.log("quoteId: " + quoteId);
    console.log(pApp.session.sessionId);
    //option2: create EAIWS session using eaiws baseurl and startup (not supported in pCon.cloud)
    //await pApp.session.open("https://www.example-eaiws.com", {startup: example-startup"});
    // pApp.session.connect("https://s2.eaiws.pcon-solutions.com/4.14.1-001/", "a63abde6-b34b-477e-9b73-1a478ceb3d73", 69120);
    const user = await axios.get("https://login.pcon-solutions.com/api/v1/account/user", {
        headers: {
            Authorization: "Bearer " + pApp.loginHandler.accessToken,
        },
    });
    console.log(user.data);
    //prepare/intialize the session
    await preareEaiwsSession(pApp, quoteId);
    //build the configuration message and send it to pCon.basket
    let tMessage = {
        type: "wbk.configuration",
        parameter: {
            application: {
                theme: {
                    //use custom application colors
                    primaryColor: "#00A000",
                    appBarColor: "#505050",
                },
                //featureSet: "Configurator", //enable configurator mode
                showUser: true,
                showProjectSaveButton: true,
                dataLanguage: ["nb", "en", "da"], //use english language
            },
            user: {
                /* The pCon.login access token should be provided here, to activate all application features.
                 * e.g. accessing protected PIM article information or to access user/organization preferences
                 * Note: The access token usually expires after 1 hour. Its important to request a new token before that happens and send it
                 * to the basket using the `wbk.updateUserAccessToken` message.
                 */
                accessToken: pApp.loginHandler.accessToken,
                name: user.data.email,
                fullName: user.data.first_name + " " + user.data.last_name,
                restrictions: ["item.setArticle.create"],
            },
            eaiws: {
                baseUrl: pApp.session.baseUrl,
                sessionId: pApp.session.session.sessionId,
                keepAliveInterval: 0, //disable keep alive, its already handled on our side
            },
            calculation: {
                pricingProcedure: PRICING_PROCEDURE_NAME, //use different pricing procedure (optional)
            },
            project: {
                title: user.data.first_name + " " + user.data.last_name,
                titleEditable: true,
                applySessionDefaults: [
                    "PartyInCharge",
                    "DataLanguage",
                    "ProjectValidity",
                ],
                openCatalogForEmptyProjects: true, //start in catalog
            },
            report: {
                templates: [REPORT_TEMPLATE],
                defaultTemplate: DEFAULT_REPORT_TEMPLATE,
            },
            catalog: {
                customCatalogs: await getCustomCatalogConfiguration(),
            },
        },
    };
    pApp.sendMessageToBasket(tMessage);
}
/**
 * This function is used to initailize the EAIWS session.
 */
async function preareEaiwsSession(pApp, quoteId) {
    var _a;
    //get selected file
    let tInputField = document.getElementById("fileInput");
    let tSelectedFile = tInputField.files != null ? tInputField.files[0] : null;
    if (quoteId != "") {
        try {
            const res = await axios.get(envi(new URL(window.location.href).toString()) +
                "Quote/GetObk?quoteId=" +
                quoteId);
            console.log("after axios;");
            if (res.status == 200) {
                const myFile = dataURLtoFile(res.data.fileContent, "base64enc.obk");
                if (myFile != null) {
                    await loadOBK(pApp.session, myFile);
                }
            }
        }
        catch (error) {
            if (((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) == 404) {
                console.log("No OBK found for this quote");
            }
            else
                throw error;
        }
    }
    else if (tSelectedFile != null &&
        tSelectedFile.name.toLowerCase().endsWith(".obk")) {
        //OBK selected -> load OBK
        await loadOBK(pApp.session, tSelectedFile);
    }
    else if (tSelectedFile != null &&
        tSelectedFile.name.toLowerCase().endsWith(".obx")) {
        //OBX selected -> load OBX
        /*
        It is necessary to activate the price calculation before loading an OBX in order to load the discounts from the OBX.
        This step can be removed if the basket calculation is not used.
        */
        await pApp.session.basket.addPriceCalculation(PRICING_PROCEDURE_NAME);
        await loadOBX(pApp.session, tSelectedFile);
    }
    else {
        //no file -> create new/empty project with prefilled header data
        // await setExampleProjectData(pApp.session);
        // await setExampleCustomerAddress(pApp.session);
    }
    //setup our price conditions
    await setupPriceConditions(pApp.session);
    try {
        const currencyRes = await axios.get(envi(new URL(window.location.href).toString()) +
            "Quote/Currency?quoteId=" +
            quoteId);
        console.log(currencyRes.data);
        await pApp.mSession.basket.setCurrency(currencyRes.data);
        const currentTaxScheme = await pApp.mSession.basket.getCurrentTaxScheme();
        console.log(currentTaxScheme);
        if (currentTaxScheme !== "NO") {
            await pApp.mSession.basket.selectCurrentTaxScheme("NO");
            const newCurrentTaxScheme = await pApp.mSession.basket.getCurrentTaxScheme();
            console.log(newCurrentTaxScheme);
        }
        await pApp.mSession.basket.setLanguages(["no"], "Default");
        await setExchangeRates(pApp);
    }
    catch (error) {
        alert(error.message);
    }
    const quoteRes = await axios.get(envi(new URL(window.location.href).toString()) +
        "Quote?quoteId=" +
        quoteId);
    const account = await setAccountData(quoteRes.data.customerId);
    const contact = await setContactData(quoteRes.data.contactId);
    console.log(quoteRes.data);
    setExampleProjectData(pApp.session, quoteRes.data, account);
    // setExampleBillingAddress(pApp.session, quoteRes.data);
    // setExampleShippingAddress(pApp.session, quoteRes.data);
    await setExampleCustomerAddress(pApp.session, quoteRes.data, account, contact);
}
function dataURLtoFile(dataurl, filename) {
    var _a;
    var arr = dataurl.split(",");
    var mime = (_a = arr[0].match(/:(.*?);/)) === null || _a === void 0 ? void 0 : _a[1];
    var bstr = atob(arr[arr.length - 1]);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}
