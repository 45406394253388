/**
 * This function loads and OBK file.
 */
export async function loadOBK(pSession, pFile) {
    //get upload URL from EAIWS
    const tUrl = await pSession.session.getUploadURL("Project");
    //upload file (using PUT request)
    await pSession.uploadFileToUrl(tUrl, pFile);
    //load session from uploaded obk
    await pSession.session.loadSession(tUrl);
}
/**
 * This function loads and OBX file.
 */
export async function loadOBX(pSession, pFile) {
    //get upload URL from EAIWS
    const tUrl = await pSession.session.getUploadURL("CutBuffer");
    //upload file (using PUT request)
    await pSession.uploadFileToUrl(tUrl, pFile);
    //insert obx into the session
    await pSession.basket.paste(null, null, tUrl);
}
