import { ajax } from "@easterngraphics/wcf/modules/utils/async";
/**
 * Request an EAIWS session from the Gatekeeper with fallback handling.
 * @param pSession EAIWS session object
 * @param pGatekeeperId The gatekeeper id which should be used
 * @param pAccessToken Access token aquired from pCon.login
 */
export async function getSessionFromGatekeeper(pSession, pGatekeeperId, pAccessToken) {
    try {
        const tOptions = {
            userToken: pAccessToken
        };
        const tResponse = await ajax('POST', 'https://eaiws-server.pcon-solutions.com/v3/session/' + pGatekeeperId, tOptions, {
            dataType: "json",
            retryAttempts: 0,
            timeout: 10000 //use fallback after 10 seconds
        });
        //connect to the received session
        pSession.connect(tResponse.server, tResponse.sessionId, tResponse.keepAliveInterval * 1000);
    }
    catch (pError) {
        console.log("Failed to start gatekeeper session: " + pError);
        return (getSessionFromFallbackGatekeeper(pSession, pGatekeeperId, pAccessToken));
    }
}
/**
 * Function to request an EAIWS session from the fallback Gatekeeper server.
 */
async function getSessionFromFallbackGatekeeper(pSession, pGatekeeperId, pAccessToken) {
    const tOptions = {
        userToken: pAccessToken
    };
    console.log("Using fallback gatekeeper server.");
    const tResponse = await ajax('POST', 'https://eaiws-server-fallback.pcon-solutions.com/v3/session/' + pGatekeeperId, tOptions, {
        dataType: "json",
        retryAttempts: 0,
        timeout: 60000
    });
    pSession.connect(tResponse.server, tResponse.sessionId, tResponse.keepAliveInterval * 1000);
}
