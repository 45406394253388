//add core-js polyfills
import "@easterngraphics/wcf/modules/polyfill/core-js";
import { Application } from "./Application";
//create the Application
const tApp = new Application();
//bind event handlers
window.startBasket = () => void tApp.startBasket();
window.savePDF = () => void tApp.savePDF();
window.saveOBK = () => void tApp.saveOBK();
window.saveOBX = () => void tApp.saveOBX();
window.restart = () => void tApp.restart();
window.clearFileInput = () => {
    document.getElementById("fileInput").value = "";
};
