import axios from "axios";
export function envi(stringUrl) {
    const env = stringUrl.includes("localhost")
        ? "https://app-webapi-hff-dev.azurewebsites.net/api/"
        : stringUrl.includes("witty-pebble")
            ? "https://app-webapi-hff-test.azurewebsites.net/api/"
            : stringUrl.includes("pcon-test.holmris-ff.no")
                ? "https://app-webapi-hff-test.azurewebsites.net/api/"
                : stringUrl.includes("pcon.holmris-ff.no")
                    ? "https://app-webapi-hff-prod.azurewebsites.net/api/"
                    : stringUrl.includes("white-plant")
                        ? "https://app-webapi-hff-prod.azurewebsites.net/api/"
                        : "https://app-webapi-hff-dev.azurewebsites.net/api/";
    return env;
}
export function enviDebug(stringUrl) {
    const env = stringUrl.includes("localhost")
        ? "https://localhost:7215/api/"
        : stringUrl.includes("witty-pebble")
            ? "https://app-webapi-hff-test.azurewebsites.net/api/"
            : stringUrl.includes("pcon-test.holmris-ff.no")
                ? "https://app-webapi-hff-test.azurewebsites.net/api/"
                : stringUrl.includes("pcon.holmris-ff.no")
                    ? "https://app-webapi-hff-prod.azurewebsites.net/api/"
                    : stringUrl.includes("white-plant")
                        ? "https://app-webapi-hff-prod.azurewebsites.net/api/"
                        : "https://app-webapi-hff-dev.azurewebsites.net/api/";
    return env;
}
export async function setExchangeRates(pApp) {
    var _a;
    let data;
    const res = await axios.get(envi(new URL(window.location.href).toString()) +
        "TransactionCurrency/All");
    if (res.status == 200) {
        data = res.data;
        console.log(data);
        const allCurrencies = data.map((c) => c.isoCurrencyCode);
        let getRates = await pApp.mSession.basket.getExchangeRates(allCurrencies);
        getRates.forEach((rate) => {
            const matchingCurrency = data.find((c) => c.isoCurrencyCode === rate.currency);
            if (matchingCurrency) {
                rate.rate = matchingCurrency.exchangeRate;
            }
        });
        console.log(getRates);
        const referenceCurrency = (_a = data.find((c) => c.exchangeRate === 1)) === null || _a === void 0 ? void 0 : _a.isoCurrencyCode;
        if (referenceCurrency) {
            await pApp.mSession.basket.setExchangeRates(getRates, referenceCurrency);
        }
    }
}
